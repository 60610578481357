@use "theme/variables" as *;

.app .l-main-col.extra-submenu {

    .c-tabs.type-nested-view {

        .panels {

            .c-tab-panel {

                .view-container {
                    height: calc(var(--app-height) - var(--header-size));
                }
            }
        }
    }
}

.c-tabs {

    &.type-default {

        .tabs-container {
            background: $default-5;
        }

        .panels {

            .c-tab-panel {
                display: none;
                flex-direction: column;

                &.active {
                    display: flex;
                }
            }
        }
    }

    &.type-nested-view {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: $spacer-1 * 1.5;

        .tabs-container {
            background: $default-6;

            .header {
                align-items: center;
                background: $info-5;
                border-bottom: 2px solid $info-2;
                border-top: 2px solid transparent;
                color: $info-2;
                cursor: pointer;
                display: flex;
                font-weight: 600;
                gap: $spacer-1;
                height: $spacer-6;
                margin-right: $spacer-3;
                padding: 0 $spacer-1 * 2.5;
                text-transform: uppercase;
                width: $panel-filters-width + $spacer-3;

                svg {
                    min-width: $icon-d;
                }

                span {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .panels {
            display: flex;
            flex: 1;

            .c-tab-panel {
                flex: 1;

                &.view {
                    flex-direction: column;
                    height: calc(var(--app-height) - var(--header-size));
                    overflow-y: scroll;
                    padding: var(--query-spacer);
                }

                .view-container {
                    flex: 1;
                }
            }
        }
    }


    .tabs-container {
        box-shadow: 0 -2px 4px rgb(0 0 0 / 10%);
        display: flex;
        width: 100%;

        .tabs {
            display: flex;
            gap: $spacer-1;
            height: $spacer-6;

            .c-tab {
                align-items: center;
                background: $surface-5;
                border: 0;
                border-bottom: 2px solid transparent;
                border-left: 2px solid $default-4;
                border-right: 2px solid $default-4;
                border-top: 2px solid transparent;
                color: $grey-2;
                display: flex;
                gap: $spacer-1;
                justify-content: center;
                padding: $spacer-05 $spacer-2;
                transition: border-bottom-color 0.5s ease;
                user-select: none;

                &:focus {
                    background: $surface-4;
                    border-bottom-color: $info-1;
                    color: $surface-2;
                }

                &.disabled {
                    background: $grey-6;
                    color: $grey-4;
                    cursor: not-allowed;
                }

                &:not(.disabled) {
                    cursor: pointer;

                    &:hover,
                    &.active {
                        background: $info-5;
                        border-bottom-color: $info-1;

                        svg {
                            color: $info-2;
                        }

                        &:focus {
                            background: $info-4;
                        }
                    }
                }
            }
        }
    }

    .c-tab-panel {
        display: none;

        &.active {
            display: flex;
        }
    }


    @media (max-width: $breakpoint-10) {

        .tabs-container {

            .tabs {
                flex-wrap: wrap;
                gap: $spacer-1;
                height: unset;

                .c-tab {
                    flex-direction: row;
                    justify-content: flex-start;
                }
            }
        }
    }
}
